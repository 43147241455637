@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
}

/* hide number increase decrease button */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}